import api from '../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'

export const usePostStep3InsuranceChange = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void,
  id?: string,
  data?: any,
) => {
  const queryClient = useQueryClient()

  return useMutation((data: any) => api.post(`${ApiRouteEnum.OFFERS}/${id}/step3/insuranceChange`, data), {
    mutationKey: [`post-${ApiRouteEnum.OFFERS}/step3/insuranceChange`],
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ApiRouteEnum.OFFERS, id] })
      successCallBack(data.data)
    },
    onError: (error) => errorCallBack(error),
  })
}
